import { Edit, ReferenceInput, required, SimpleForm, TextInput, useRecordContext } from "react-admin";
import Alert from '@mui/material/Alert';
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";


const CompaniesEdit = () => (
  <>
    <Alert severity="warning">Attention ! Toutes modifications sur ce client affectera tous les postes actifs de ce client.</Alert>
    <Edit title={<CompaniesTitle />} >
      <SimpleForm>
        <CustomTextInput variant="outlined" source="name" label="Nom de l'entreprise" validate={[required()]} />
      </SimpleForm>
    </Edit>
  </>
);
export default CompaniesEdit;

const CompaniesTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.name}` : ''}</span>
}