import { useEffect, useState } from "react";
import { fetchUtils } from "react-admin";
import { useParams } from 'react-router-dom';
import API_URL from "../../../utils/var";
import Card from '@mui/material/Card';
import { CardContent, CardMedia, Divider } from "@mui/material";
import './ResultsView.css'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import image from '../../../assets/header.png'
import '../Recipients.css'



const httpClient = fetchUtils.fetchJson;


const ResultView = () => {
  const { candidatureId, token } = useParams();
  const [jobData, setJobData] = useState<any>();
  const [results, setResults] = useState<any[]>();
  const [openModal, setOpenModal] = useState(false);
  const [skill, setSkill]: any = useState();
  const [modalTitle, setModalTitle] = useState("");
  const locale = navigator.language;
  const handleClick = (skill: any) => {
    setModalTitle(skill.skill.title);
    setSkill(skill);
    setOpenModal(!openModal);
  }
  useEffect(() => {
    const url2 = `${API_URL}/candidate/${candidatureId}/${token}`;
    const url = `${API_URL}/candidature/statistics/${locale}/${candidatureId}/${token}`;
    httpClient(url2).then(({ body, json }) => (
      {
        data: json,
      })).then(res => setJobData(res.data));
    httpClient(url).then(({ body, json }) => (
      {
        data: json,
      })).then(res => setResults(res.data));

  }, [])
  return (
    <>
      <div className='header'>
        <div className='titleLhh'>LHH</div>
        <div style={{ display: "flex", flexDirection: 'column' }}>
          <span style={{ color: 'white' }}>{jobData && jobData.candidate.firstname + " " + jobData.candidate.lastname + " - " + jobData.job.title + " - " + jobData.job.company.name}</span>
        </div>
        <img src={image} alt="logo" style={{ height: "80%" }}></img>
      </div>
      <div style={{ display: "flex", flexWrap: 'wrap', width: '80%', justifyContent: 'space-between', alignItems: 'stretch', margin: '0 auto' }}>
        {results && results.map((skill) => (
          <>
            <Card key={skill.skill.id} style={{ width: 'calc(50% - 1em)', marginBottom: '1em' }} onClick={() => handleClick(skill)}>
              <CardContent>
                <div style={{ fontWeight: 'bold', marginBottom: '3%' }}>{skill.skill.title}</div>
                <Divider />
                <div style={{ display: 'flex', alignItems: 'stretch' }}>
                  <div style={{ flexGrow: 1, padding: '1em' }}>
                    <div>
                      Moyenne : {skill.average}/5
                    </div>
                    <div>
                      Nombre de réponses : {skill.answers}
                    </div>
                  </div>
                  <CardMedia component="img" src={process.env.PUBLIC_URL + `/assets/skills/icon-bg-${skill.skill.icon}.png`} style={{ width: '30%', objectFit: 'cover' }} />
                </div>
              </CardContent>
            </Card>
          </>
        ))
        }
        <Dialog open={openModal} fullWidth maxWidth='xl'>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
            {modalTitle}
            <CloseIcon onClick={() => setOpenModal(!openModal)} />
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '3% 0', backgroundColor: '#F3F6F9' }}>
              <span>Nombres de réponses : {skill && skill.answers}</span>
              <span>Moyenne générale: {skill && skill.average}</span>
            </div>
            {skill && skill.scores.map((score: any) => (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1%' }}>
                <span>{score.question.behavior}</span>
                <span>{score.average}</span>
              </div>
            ))}
            <p style={{ fontWeight: 'bold', color: '#3F4254' }}>Points forts</p>
            <div>
              {skill && skill.strengths.map((strengths: any) => (
                <Chip label={strengths.tag + " (" + strengths.count + ")"} color="primary" style={{ marginRight: '1%' }} />
              ))}

            </div>
            <p style={{ fontWeight: 'bold', color: '#3F4254' }}>Points à améliorer</p>
            <div>
              {skill && skill.weaknesses.map((weaknesses: any) => (
                <Chip label={weaknesses.tag + " (" + weaknesses.count + ")"} color="primary" style={{ marginRight: '1%' }} />
              ))}
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(!openModal)}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    </>
  )
}
export default ResultView;