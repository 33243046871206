import { Layout, LayoutProps } from 'react-admin';
import AppBarCustom from './AppBarCustom';
import MenuCustom from './MenuCustom';
import MySideBar from './MySideBar';

const CustomLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => {
    return (
        <Layout {...props} sidebar={MySideBar} appBar={AppBarCustom} />
    )
}
export default CustomLayout;