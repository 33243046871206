/* eslint-disable no-new-object */
import { Button, fetchUtils, useRecordContext, useRefresh } from "react-admin";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import API_URL from "../../utils/var";
import { Snackbar } from "@material-ui/core";
import { useState } from "react";
import Alert from '@mui/material/Alert';


const httpClient = fetchUtils.fetchJson;

const RecipientsValidation = (props: any) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const [openToast, setOpenToast] = useState(false);
    const [openToastRefused, setOpenToastRefused] = useState(false);
    const [validated, setValidated] = useState(record.validated);
    async function ValidateRecipient(props: any) {
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
            method: "PATCH",
            body: props === 1 ? JSON.stringify(new Object({ validation: 1 })) : JSON.stringify(new Object({ validation: 2 }))
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        await httpClient(`${API_URL}/consultant/validate/recipients/${record.id}`, options).then(() => {
            props === 1 ? setOpenToast(true) : setOpenToastRefused(true);
            setValidated(1);
            refresh();
        });


    }
    if (validated === 0) {
        return (
            <>
                <Button style={{ color: 'green' }} children={<DoneIcon />} onClick={() => ValidateRecipient(1)}></Button>
                <Button style={{ color: 'red' }} children={<CloseIcon />} onClick={() => ValidateRecipient(2)}></Button>
                <Snackbar autoHideDuration={6000} open={openToast} onClose={() => setOpenToast(false)}>
                    <Alert onClose={() => setOpenToast(false)} severity="success" sx={{ width: '100%' }}>
                        Référent validé avec succès.
                    </Alert>
                </Snackbar>
                <Snackbar autoHideDuration={6000} open={openToastRefused} onClose={() => setOpenToastRefused(false)}>
                    <Alert onClose={() => setOpenToastRefused(false)} severity="success" sx={{ width: '100%' }}>
                        Référent refusé avec succès.
                    </Alert>
                </Snackbar>
            </>
        )
    }
    return <span></span>
}
export default RecipientsValidation;