import { List, TextField, DateField, DeleteWithConfirmButton, EditButton, WithRecord, SearchInput, } from "react-admin";
import CustomDatagrid from "../../components/CustomDatagrid/CustomDatagrid";

const CompaniesList = () => {
  // const isSmall = useMediaQuery((theme : any) => theme.breakpoints.down("sm"));
  return (
    <List filters={companyFilter} >
      <CustomDatagrid>
        <TextField source="name" label="Nom de l'entreprise" sortBy="name" />
        <DateField source="created_at" label="Créé le" sortBy="created_at" />
        <EditButton label="" />
        <WithRecord render={(record) => <DeleteWithConfirmButton confirmTitle={"Supprimer " + record.name} label="" confirmContent="Vous êtes sur de vouloir supprimer cette entreprise ?" style={{ marginRight: '10%' }} />} />
      </CustomDatagrid>
    </List>
  );
};
export default CompaniesList;

const companyFilter = [
  <SearchInput source="q" alwaysOn />
]
