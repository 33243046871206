import { useRecordContext } from "react-admin";
import Chip from '@mui/material/Chip';


const HierarchicalLink = (props : any) =>{
    const record = useRecordContext();
    let link = "";
    if(record){
        switch(record.hierarchical_link){
            case 1: 
                link = "Collègue"
                break;
            case 2:
                link = "Manager"
                break;
            case 3 :
                link= "N-1"
                break;
            case 4 : 
                link = "RH"
                break;
            case 5 : 
                link="Client"
                break;
            default:
                break;
        }
    }
    return <Chip label={link} variant="outlined" />

}
export default HierarchicalLink;