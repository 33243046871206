import { MenuItemLink, UserMenu } from "react-admin";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import authProvider from "../../admin/AuthProvider";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const UserMenuCustom = () => {
    return (
        <UserMenu label='test'>
            <MenuItemLink
                to="/profile"
                primaryText="Profile"
                leftIcon={<AccountBoxIcon />}
            />
            <MenuItemLink
                to="/login"
                primaryText="Logout"
                leftIcon={<PowerSettingsNewIcon />}
                onClick={() => authProvider.logout(false)}
            />
        </UserMenu>
    )
}
export default UserMenuCustom;