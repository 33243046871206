import { Button, List, TextField, TopToolbar, fetchUtils, useRedirect } from "react-admin";
import { useParams } from "react-router-dom"
import HierarchicalLink from "../../components/fields/HierarchicalLink";
import AddIcon from '@mui/icons-material/Add';
import TagCandidateStatus from "../../components/fields/TagCandidateStatus";
import CustomDatagrid from "../../components/CustomDatagrid/CustomDatagrid";
import image from '../../assets/header.png'
import './Recipients.css'
import API_URL from "../../utils/var";
import { useEffect, useState } from "react";
import EmptyList from "./EmptyList";


const httpClient = fetchUtils.fetchJson;

const RecipientsExternalList = () => {
    const [jobData, setJobData] = useState<any>();
    let { candidatureId, token } = useParams();
    const array = []
    array.push(token, candidatureId);
    const url = `${API_URL}/candidate/${candidatureId}/${token}`;

    useEffect(() => {
        httpClient(url).then(({ body, json }) => (
            {
                data: json,
            })).then(res => setJobData(res.data));
    }, [])
    return (
        <>
            {jobData && (<>
                <div className='header'>
                    <div className='titleLhh'>LHH</div>
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                        <span style={{ color: 'white' }}>{jobData && jobData.candidate.firstname + " " + jobData.candidate.lastname + " - " + jobData.job.title + " - " + jobData.job.company.name}</span>
                    </div>
                    <img src={image} alt="logo" style={{ height: "80%" }}></img>
                </div>
                <div style={{ padding: '2em' }}>
                    <List empty={<EmptyList token={token} candidatureId={candidatureId} />} pagination={false} actions={<ListActions />} disableAuthentication resource="candidate" queryOptions={{ meta: { candidatureId: candidatureId, token: token } }}>
                        <CustomDatagrid bulkActionButtons={false} >
                            <TextField source="user.firstname" label="Prénom du répondant" />
                            <TextField source="user.lastname" label="Nom du répondant" />
                            <TextField source="user.email" label="Email du répondant" />
                            <TextField source="user.company.name" label="Entreprise" />
                            <TextField source="user.job_title" label="Fonction du référent" />
                            <TextField source="user.phone" label="Téléphone du référent" />
                            <HierarchicalLink source="hierarchical_link" label="Lien hiérarchique" />
                            <TagCandidateStatus source="validated" props={array} label="Statut" />
                        </CustomDatagrid>
                    </List>
                </div>
            </>
            )}

        </>

    )
}
export default RecipientsExternalList;

const ListActions = () => {
    let { candidatureId, token } = useParams();
    const redirect = useRedirect();
    const onClick = () => {
        redirect(`/candidate/${token}/${candidatureId}/create`)
    }
    return (
        <TopToolbar>
            <Button onClick={onClick} label="Créer" children={<AddIcon />} />
        </TopToolbar>
    )
};