import { Datagrid, DateField, List, TextField, useRecordContext } from "react-admin";
import CustomDatagrid from "../CustomDatagrid/CustomDatagrid";

const RecipientsDetails = () => {
    const record = useRecordContext();
    const data = [];
    data.push(record);
    return (
        <div style={{ margin: '3em', display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '3%' }}>
            <span style={{
                textAlign: 'center', textTransform: 'uppercase',
                color: '#581F64',
                font: 'normal normal bold 12px/20px Arial'
            }}>Détails sur le référent</span>
            <List hasCreate={false} pagination={false} exporter={false} >
                <CustomDatagrid bulkActionButtons={false} data={data}>
                    <TextField source="user.lastname" label="Nom" />
                    <TextField source="user.firstname" label="Prénom" />
                    <TextField source="user.email" label="Email" />
                    <DateField source="created_at" label="Crée le" />
                    <DateField source="updated_at" label="Mis à jour le" />
                </CustomDatagrid>
            </List>
        </div>
    )
}
export default RecipientsDetails;