/* eslint-disable no-new-object */
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import API_URL from '../utils/var';


const httpClient = fetchUtils.fetchJson;


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getList: (resource: any, params?: any) => {
        if (resource === "candidate") {
            const url = `${API_URL}/candidate/${params.meta.candidatureId}/${params.meta.token}`;
            return httpClient(url).then(({ body, json }) => {
                return (
                    {
                        data: json.recipients,
                        total: json.recipients.length,
                    })
            });
        }
        const { page, perPage } = params && params.pagination ? params.pagination : "";
        const { field, order } = params && params.sort ? params.sort : "";
        const query = {
            sort: field,
            order: order,
            start: page ? JSON.stringify((page - 1) * perPage) : "",
            end: page ? JSON.stringify(page * perPage) : "",
            filter: params?.filter ? Object.keys(params && params.filter).length !== 0 ? params && params.filter.q : "" : "",
            validation_status: params && params.filter.validation_status !== undefined ? params.filter.validation_status : "",
            answer_status: params && params.filter.answer_status !== undefined ? params.filter.answer_status : "",
            selected_consultant: params && params.filter.selected_consultant !== undefined ? params.filter.selected_consultant : localStorage.getItem("userId"),
            candidature_status: params && params.filter.candidature_status !== undefined ? params.filter.candidature_status : "",
            client: params && params.filter.clientId,
            status: params && params.filter.status !== undefined ? params.filter.status : "",
        };
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        // si resource = mandat on vient appeler job car l'objet mandat n'existe pas en BDD 
        let url = resource === "mandats" ? `${API_URL}/jobs?${stringify(query)}` : `${API_URL}/${resource}?${stringify(query)}`;
        return httpClient(url, options).then(({ json }) => (
            {
                data: json.results,
                total: json.total,
            })
        );
    },

    getOne: (resource: any, params: any) => {
        const urlSplitted = window.location.href.split('/');
        if (resource === "candidate") {
            const query = {
                candidature_id: urlSplitted[6]
            }
            let url = `${API_URL}/${resource}/recipients/${urlSplitted[8]}/${urlSplitted[5]}?${stringify(query)}`;
            return httpClient(url, {
                method: 'GET',
            }).then(({ json }) => ({
                data: json,
            }))
        }
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        if (resource === "profile") {
            return httpClient(`${API_URL}/logged/user`, options).then(({ json }) => ({ data: json }));
        }
        return httpClient(`${API_URL}/${resource}/${params.id}`, options).then(({ json }) => {
            if (resource === "jobs") {
                let skillsRecord: any = [];
                json.skills_path.linked_skills.forEach((linkedSkill: any) => {
                    skillsRecord.push(linkedSkill.skill.id);
                })
                json.skills_path.linked_skills = skillsRecord;
            }

            return ({
                data: json
            })
        })
    },

    getMany: (resource: any, params: { ids: any; }) => {
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        const query = {
            filter: { ids: params.ids },
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;
        return httpClient(url, options).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource: any, params: { pagination: { page: any; perPage: any; }; sort: { field: any; order: any; }; filter: any; target: any; id: any; }) => {
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;

        return httpClient(url, options).then(({ body, json }) => ({
            data: json,
            total: JSON.parse(body).count,
        }));
    },

    create: (resource: any, params: { data: any; meta?: any }) => {
        let url = `${API_URL}/${resource}`;
        let finalData = params.data;
        switch (resource) {
            case "jobs":
                if (params.meta.duplication === "false") {
                    finalData = new Object({
                        title: params.data.title,
                        company_id: params.data.company_id,
                        new_company: params.data.new_company,
                        skill_ids: params.data.skill_ids,
                        code: params.data.code,
                        is_duplication: false,
                    })
                } else {
                    finalData = new Object({
                        title: params.data.title,
                        company_id: params.data.company_id,
                        new_company: params.data.new_company,
                        skills_path_id: params.data.skills_path.id,
                        code: params.data.code,
                        is_duplication: true,
                    })
                }
        }
        if (resource === "candidate/recipients") {
            url = `${API_URL}/${resource}/${params.meta.token}`
            params.data = { ...params.data, candidature_id: params.meta.candidatureId };
            return (httpClient(url, {
                method: 'POST',
                body: JSON.stringify(params.data)
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            }))
            )
        }
        if (resource === "recipients") {
            url = `${API_URL}/consultant/${resource}`;
        }
        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        return (httpClient(url, {
            method: 'POST',
            body: JSON.stringify(finalData),
            headers: headers,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
        )
    },

    update: (resource: any, params: any) => {
        let dataSet: any = params.data;
        let url = `${API_URL}/${resource}/${params.id}`;
        if (resource === "candidate") {
            url = `${API_URL}/${resource}/recipients/${params.meta.id}/${params.meta.token}`;
            dataSet = new Object({
                firstname: params.data.user.firstname,
                lastname: params.data.user.lastname,
                email: params.data.user.email,
                company_name: params.data.user.company.name,
                recipient_job_title: params.data.user.job_title,
                hierarchical_link: params.data.hierarchical_link,
                phone: params.data.user.phone,
                candidature_id: params.meta.candidatureId,
            })
            return httpClient(url, {
                method: 'PATCH',
                body: JSON.stringify(dataSet),
            }).then(({ json }) => ({ data: json }))
        }
        switch (resource) {
            case "candidatures":
                dataSet = new Object({
                    id: params.data.id,
                    firstname: params.data.candidate.firstname,
                    lastname: params.data.candidate.lastname,
                    email: params.data.candidate.email,
                    phone: params.data.candidate.phone,
                    job_id: params.data.job.id,
                    status: params.data.status,
                })
                break;
            case "recipients":
                dataSet = new Object({
                    id: params.data.id,
                    firstname: params.data.user.firstname,
                    lastname: params.data.user.lastname,
                    email: params.data.user.email,
                    locale: params.data.user.locale,
                    company_name: params.data.user.company.name,
                    candidature_id: params.data.candidature.id,
                    hierarchical_link: params.data.hierarchical_link,
                    recipient_job_title: params.data.user.job_title,
                    validated: params.data.validated,
                })
                url = `${API_URL}/consultant/${resource}/${params.id}`
                break;
            case "jobs":
                dataSet = new Object({
                    id: params.data.id,
                    title: params.data.title,
                    company_id: params.data.company.id,
                    skill_ids: params.data.skills_path.linked_skills,
                    status: params.data.status,
                    code: params.data.code,
                })
                break;
            case "profile":
                url = `${API_URL}/users/${localStorage.getItem('userId')}`
                break;
        }
        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        return httpClient(url, {
            method: 'PATCH',
            body: JSON.stringify(dataSet),
            headers: headers,
        }).then(({ json }) => ({ data: json }))
    }
    ,

    updateMany: (resource: any, params: { ids: any; data: any; }) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    delete: (resource: any, params: any) => {
        let url = `${API_URL}/${resource}/${params.id}`;
        if (resource === "candidate") {
            url = `${API_URL}/${resource}/recipients/${params.id}/${params.meta.token}`
            return (httpClient(url, {
                method: 'DELETE',
                body: JSON.stringify(new Object({ candidature_id: params.meta.candidatureId }))
            }).then(({ json }) => ({ data: json })))
        }
        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        return (
            httpClient(url, {
                method: 'DELETE',
                headers: headers,
            }).then(({ json }) => ({ data: json }))
        )
    }
    ,

    deleteMany: (resource: any, params: any) => {
        let query;
        let url;
        switch (resource) {
            case "recipients":
                query = {
                    recipients_ids: JSON.stringify({ id: params.ids }),
                }
                url = `${API_URL}/consultant/${resource}?${stringify(query)}`

                break;
            case "candidatures":
                query = {
                    candidatures_ids: JSON.stringify({ id: params.ids })
                }
                url = `${API_URL}/${resource}?${stringify(query)}`

                break;
            default:
                query = {
                    filter: JSON.stringify({ id: params.id })
                }
                url = `${API_URL}/${resource}?${stringify(query)}`

        }

        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        return httpClient(url, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
            headers: headers
        }).then(({ json }) => ({ data: json }));
    },
};
