import { AppBar } from "react-admin"
import UserMenuCustom from "./UserMenuCustom"
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: theme.palette.primary.main, // Utilise la couleur primaire du thème
    },
    title: {
        // flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // margin: '0 20px 0 0', /* ajoute la classe CSS pour la marge à droite */
        font: 'normal normal bold 35px/28px Arial'
    },
    resource: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: '0 1em 0 1em', /* ajoute la classe CSS pour la marge à droite */
        font: 'normal normal bold 14px/22px Arial',
        textTransform: 'uppercase'
    },
    spacer: {
        flex: 1,
    },
    usermenu: {
        margin: '0 0 0 20px', /* ajoute la classe CSS pour la marge à gauche */
    },
}));

const AppBarCustom = (props: any) => {
    const location = useLocation();
    const resource = location.pathname.split('/').pop();
    let resourceName = "";
    switch (resource) {
        case "recipients":
            resourceName = "référents";
            break;
        case "candidatures":
            resourceName = "candidats";
            break;
        case "jobs":
            resourceName = "postes";
            break;
        case "companies":
            resourceName = "entreprises";
            break;
        case "mandats":
            resourceName = "mandats";
            break;
    }

    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<UserMenuCustom />} color={"secondary"} >
            <span className={classes.title}>LHH |</span>
            <span className={classes.resource}> {resource ? resourceName : ""}</span>
            <span className={classes.spacer} />
            {/* <span style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', textAlign: 'right', padding: '10px 0' }}>
                test
            </span> */}
        </AppBar>
    )
}
export default AppBarCustom