import { BooleanInput, Edit, email, required, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import CustomTextInput from "../components/CustomInputs/CustomTextInput";

const ProfilePage = () => {
    const userId = localStorage.getItem('userId')
    return (
        <Edit resource="profile" redirect={false} id={userId ? userId : ""} >
            <SimpleForm toolbar={<UserEditToolbar />}>
                <CustomTextInput source="firstname" />
                <CustomTextInput source="lastname" />
                <CustomTextInput source="email" type="email" validate={[email(), required()]} />
                <CustomTextInput source="phone" label="Téléphone" />
                {/* <TextInput source="locale" label="Language" /> */}
                <BooleanInput source="receive_answer_confirmation_email" label="Réception d'un email à chaque réponse d'un référent" />
            </SimpleForm>
        </Edit>
    )
}
export default ProfilePage;

const UserEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);