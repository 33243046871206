/* eslint-disable no-new-object */
import { useState } from "react";
import { Button, CheckboxGroupInput, fetchUtils } from "react-admin";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import API_URL from "../../utils/var";
import { Dialog, DialogTitle } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import TemplatePdf1 from "./TemplatePdf1";
import TemplatePdf2 from "./TemplatePdf2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Divider } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

const httpClient = fetchUtils.fetchJson;

const labels: { [index: string]: string } = {
    1: 'Collègue',
    2: 'Manager',
    3: 'N-1',
    4: 'RH',
    5: 'Client',
};

const PdfExport = ({ candidature, nbrAnswer }: any) => {
    const exportable = parseInt(nbrAnswer) > 0 ? false : true;
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState<any[]>();
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const [dataToTemplate, setDataToTemplate] = useState();
    const handleClick = async () => {
        const url = `${API_URL}/candidatures/comments/${candidature.id}`
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        await httpClient(url, options).then(({ json }: any) => (
            {
                data: json,
            })).then((res: any) => {
                setComments(res.data.results);
            });
        setOpen(true);
    }
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: any = event.target.value;
        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions[value] !== undefined) {
                return { ...prevSelectedOptions, [value]: event.target.checked };
            } else {
                return { ...prevSelectedOptions, [value]: event.target.checked };
            }
        });
    };
    const handleClose = () => {
        setTextAreaValue("");
        setSelectedOptions([]);
        setOpen(false);
    }
    const sendComments = async () => {
        const recipient_ids = Object.entries(selectedOptions).filter(([key, value]) => value === true).map(([key, value]) => key);
        const consultant_comment = textAreaValue;
        const url2 = `${API_URL}/candidatures/generate/pdf/${candidature.id}`;
        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        await httpClient(url2, {
            method: 'POST',
            body: JSON.stringify(new Object({ recipient_ids: recipient_ids, consultant_comment: consultant_comment })),
            headers: headers,
        }).then(res => {
            setDataToTemplate(res.json);
            const pdf = new jsPDF("portrait", "px", "A4");
            const input = document.getElementById("print1" + candidature.id.toString());
            const input2 = document.getElementById("print2" + candidature.id.toString());

            setTimeout(() => {
                input && html2canvas(input).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    pdf.addImage(imgData, "JPEG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
                    input2 && html2canvas(input2).then((canvas2) => {
                        const imgData2 = canvas2.toDataURL("image/png");
                        pdf.addPage("a4", "portrait");
                        pdf.addImage(imgData2, "JPEG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
                        pdf.save("Rapport_prise_références.pdf");
                    });
                });
            }, 1000);
            setTextAreaValue("");
            setSelectedOptions([]);
            // setDataToTemplate(undefined);
            setOpen(false);
        })
    }
    return (
        <>
            <div style={{ position: 'absolute', left: '-99999px' }} id={"print1" + candidature.id.toString()}>
                {dataToTemplate !== undefined && <TemplatePdf1 data={dataToTemplate} />}
            </div>
            <div style={{ position: 'absolute', right: '-99999px' }} id={"print2" + candidature.id.toString()}>
                {dataToTemplate !== undefined && <TemplatePdf2 data={dataToTemplate} />}
            </div>
            <Button children={<PictureAsPdfIcon />} onClick={handleClick} disabled={exportable}></Button>
            <Dialog open={open} onClose={handleClose} style={{ width: '100%' }} maxWidth="lg">
                <DialogTitle color='primary'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Commentaires
                        <CloseIcon className='closeIcon' onClick={handleClose} />
                    </div>

                </DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <div style={{ marginBottom: '5%' }}>
                            {comments && comments.map((element) => {
                                return (
                                    <>
                                        <FormControlLabel
                                            key={element.id}
                                            control={<Checkbox
                                                key={element.id}
                                                value={element.id}
                                                checked={selectedOptions[element.id]}
                                                onChange={(handleCheckboxChange)}
                                                inputProps={{ 'aria-label': `Commentaire de ${element.user.firstname} ${element.user.lastname}` }}
                                            />}
                                            label={element.user.firstname + " " + element.user.lastname + " - " + element.user.company.name + " - " + labels[element.hierarchical_link] + ": " + element.comment}
                                        />
                                        <Divider />
                                    </>
                                )
                            })}
                        </div>
                    </FormGroup>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span className="commentTitle">AJOUTEZ UN COMMENTAIRE À AFFICHER SUR LE RAPPORT DE PRISE DE RÉFÉRENCES</span>
                        <TextField
                            label="Commentaire du consultant"
                            value={textAreaValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTextAreaValue(event.target.value)}
                            multiline
                            rows={4}
                            style={{ width: '100%' }}
                            variant="outlined"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: "#581f64" }} color='secondary' onClick={sendComments} children={<PictureAsPdfIcon />} label="Générer le rapport pdf" />
                </DialogActions>
            </Dialog>

        </>
    )
}
export default PdfExport;