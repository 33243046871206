import { Box, Button, Typography } from "@material-ui/core";
import { CreateButton, useRedirect } from "react-admin";
import AddIcon from '@mui/icons-material/Add';

const EmptyList = ({ token, candidatureId }: any) => {
    const redirect = useRedirect();
    return (
        <Box textAlign="center" m={1} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
            <Typography variant="h6" style={{ color: '#581f64', marginBottom: '2%' }} paragraph>
                CRÉER UN RÉFÉRENT
            </Typography>
            <Typography variant="body1" style={{ maxWidth: '50%', marginBottom: '10%' }}>
                Nous vous recommandons de saisir de 2 à 5 contacts pour favoriser vos chances. Le rapport vous sera envoyé à partir de 2 réponses afin de garantir la confidentialité de celles-ci.
            </Typography>
            <Button startIcon={<AddIcon />} style={{ backgroundColor: '#581f64', color: 'white' }} onClick={() => redirect(`/candidate/${token}/${candidatureId}/create`)} variant="contained">CRÉER VOTRE PREMIER RÉFÉRENT</Button>
        </Box>
    )
}
export default EmptyList;