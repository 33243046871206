/* eslint-disable no-new-object */
// in src/users.tsx
import { useEffect, useState } from "react";
import { List, Datagrid, TextField, SearchInput, DateField, AutocompleteInput, Button, fetchUtils, WithRecord, EditButton } from "react-admin";
import EmailIcon from '@mui/icons-material/Email';
import DataProvider from "../../admin/DataProvider";
import API_URL from "../../utils/var";
import PdfExport from "../../components/buttons/PdfExport";
import CustomDatagrid from "../../components/CustomDatagrid/CustomDatagrid";
import { Snackbar } from "@material-ui/core";
import Alert from '@mui/material/Alert';

const httpClient = fetchUtils.fetchJson;

export const CandidaturesList = () => {
  const [companies, setCompanies] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  // envoi d'email pour que le candidat saisisse ses répondants
  async function SendEmail(record: any) {
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
    };
    const token = localStorage.getItem('access_token');
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    await httpClient(`${API_URL}/candidatures/send/email/${record.id}`, options).then(() => setOpenToast(true));
  }
  useEffect(() => {
    const url = `${API_URL}/consultants`
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
    };
    const token = localStorage.getItem('access_token');
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    httpClient(url, options).then(({ json }) => (
      {
        data: json.results,
      })).then((res) => {
        setConsultant(res.data);
      })

    DataProvider.getList("companies").then(res => setCompanies(res.data));
  }, [])
  // const isSmall = useMediaQuery((theme : any) => theme.breakpoints.down("sm"));
  return (
    <>
      <List filters={CompanyListBuilder(companies, consultant)}>
        <CustomDatagrid>
          <TextField source="candidate.lastname" label="Nom du candidat" sortBy="candidate_lastname" />
          <TextField source="candidate.firstname" label="Prénom du candidat" sortBy="candidate_lastname" />
          <TextField source="job.title" label="Nom du poste" sortBy="job_title" />
          <TextField source="job.company.name" label="Client" sortBy="client" />
          <WithRecord render={record => <span>{record.job.consultant.firstname + " " + record.job.consultant.lastname}</span>} label="Consultant Responsable"></WithRecord>
          <WithRecord render={record => <span>{record.answers_count + "/" + record.total_count}</span>} label="Nombre d'évaluations"></WithRecord>
          <TextField source="candidate.email" label="Email du candidat" sortBy="candidate_email" />
          <TextField source="candidate.phone" label="Téléphone" sortBy="phone" />
          <DateField source="created_at" label="Crée le" sortBy="created_at" />
          <DateField source="updated_at" label="Mis à jour le" sortBy="updated_at" />
          <WithRecord render={record =>
            <Button disabled={!(localStorage.getItem("userId") == record.job.consultant.id)} children={<EmailIcon />} onClick={() => SendEmail(record)}></Button>
          } label="Email"></WithRecord>
          <WithRecord render={record =>
            <PdfExport candidature={record} nbrAnswer={record.answers_count} />
          }></WithRecord>
          <WithRecord render={record =>
            <EditButton label="" disabled={!(localStorage.getItem("userId") == record.job.consultant.id)} />
          }></WithRecord>
        </CustomDatagrid>
      </List>
      <Snackbar autoHideDuration={6000} open={openToast} onClose={() => setOpenToast(false)}>
        <Alert onClose={() => setOpenToast(false)} severity="success" sx={{ width: '100%' }}>
          Email envoyé au candidat avec succès.
        </Alert>
      </Snackbar>

    </>
  );
};

function CompanyListBuilder(companies: any, consultant: any) {
  const consultantTab: Object[] = consultant.map((e: any) => {
    return new Object({ id: e.id, name: e.lastname + " " + e.firstname });
  })
  const companiesTab: Object[] = companies.map((e: any) => {
    return new Object({ id: e.id, name: e.name });
  })
  const companyFilter = [
    <SearchInput source="q" alwaysOn />,
    <AutocompleteInput source="selected_consultant" label="Consultant" choices={consultantTab} defaultValue={localStorage.getItem("userId")} />,
    <AutocompleteInput source="clientId" label="Client" choices={companiesTab} />,
    <AutocompleteInput source="candidature_status" label="Statut de candidature" choices={[
      { id: 0, name: 'Actif' },
      { id: 1, name: 'Archivé' },
      { id: 2, name: 'Tous' },
    ]} />,
  ];

  return companyFilter;

}
