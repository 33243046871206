import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import Chip from '@mui/material/Chip';
import image from "../../assets/points-forts.png";
import image2 from "../../assets/points-de-progres.png";
import dayjs from "dayjs";
import "./PdfExport.css";
import headerLogo from '../../assets/header.png'
import utc from 'dayjs/plugin/utc';

const labels: { [index: string]: string } = {
    1: 'Collègue',
    2: 'Manager',
    3: 'N-1',
    4: 'RH',
    5: 'Client',
};

dayjs.extend(utc);
const TemplatePdf1 = (data: any) => {
    const colorsPie = ['#759016', '#FF0000'];
    const strenghtsLength = data.data.strengthsData.reduce((acc: any, str: any) => acc + str.tag.length, 0);
    const candidateInfo = data !== undefined && data.data.candidateInfo.firstname + " " + data.data.candidateInfo.lastname + " - " + data.data.candidateInfo.companyName + " - " + data.data.candidateInfo.jobTitle;
    return (
        <div
            id="divToPrint"
            className="mt4"
            style={{ height: "1123px", width: "794px" }}
        >
            <div style={{ margin: "20px", backgroundColor: "#FCFCFE" }}>
                <div
                    style={{
                        backgroundColor: "#581F64",
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        marginBottom: "40px",
                    }}
                >
                    <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", marginRight: "20%", marginLeft: "5%" }}>
                        LHH
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginRight: '15%' }}>
                        <div style={{ fontSize: "14px", color: "white", textAlign: "center" }}>
                            <div>RAPPORT DE PRISE DE REFERENCES</div>
                            <div>{candidateInfo}</div>
                            <div>Date d'édition : {dayjs.utc(data.data.candidateInfo.date).format("DD/MM/YYYY")}</div>
                        </div>
                    </div>
                    <img src={headerLogo} alt="logo" style={{ height: "80%", marginRight: '5%' }}></img>
                </div>
                <div className="container">
                    <div className="square border">
                        <div style={{ font: 'normal normal medium 14px/22px Roboto', color: '#581f64', marginTop: '5%' }}>Résultats sur les compétences</div>
                        <div style={{ height: "80%", width: "100%", marginTop: "5%" }}>
                            <ResponsiveBar
                                data={data.data.skillsData}
                                keys={['value']}
                                indexBy="label"
                                margin={{ top: 10, right: 10, bottom: 50, left: 150 }}
                                layout="horizontal"
                                padding={0.3}
                                valueScale={{ type: "linear" }}
                                indexScale={{ type: "band", round: true }}
                                colors="#581F64"
                                labelTextColor="#FFFFFFDE"
                                axisBottom={{
                                    tickValues: [0, 1, 2, 3, 4, 5],
                                    tickPadding: 4,
                                    legend: 'Notes sur 5', // Ajout de la légende
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                            />
                        </div>
                    </div>
                    <div className="square">
                        <div className="title3">Points de progrès</div>
                        <div style={{ height: "65%", marginTop: "5%" }}>
                            <img src={image2} alt="logo" style={{ height: "100%" }}></img>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {data.data.weaknessesData.length >= 2 ? (
                                <>
                                    <Chip label={data.data.weaknessesData[0].tag} className="tag green"></Chip>
                                    <Chip label={data.data.weaknessesData[1].tag} className="tag green"></Chip>
                                </>
                            ) : (
                                data.data.weaknessesData.length > 1 && (<Chip label={data.data.weaknessesData[0].tag} className="tag green"></Chip>)
                            )}

                        </div>
                    </div>
                    <div className="square">
                        <div className="title2">Points Forts</div>
                        <div style={{ height: "65%", marginTop: "5%" }}>
                            <img src={image} alt="logo" style={{ height: "100%" }}></img>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {data.data.strengthsData.map((strength: any, index: any) => (
                                <Chip
                                    key={index}
                                    label={strength.tag}
                                    className={`tagl ${strenghtsLength > 45 ? "long" : ""} ${strenghtsLength > 65 ? "very-long" : ""
                                        }${strenghtsLength > 70 ? "extrem-long" : ""
                                        }`}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="square border">
                        <div style={{ font: 'normal normal medium 14px/22px Roboto', color: '#581f64', marginTop: '5%' }}>Recommandez-vous ce candidat ? </div>
                        <div style={{ height: "80%", width: "100%", marginTop: "5%" }}>
                            <ResponsivePie
                                data={data.data.recommendedData}
                                margin={{ top: 10, right: 30, bottom: 50, left: 30 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                enableArcLinkLabels={false}
                                cornerRadius={3}
                                colors={colorsPie}
                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 12,
                                        translateY: 42,
                                        itemsSpacing: 0,
                                        itemWidth: 146,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', height: '150px', flexDirection: 'column' }}>
                    <div className="titleRef"> {data.data.recipientsNames.length} Référents ont répondu</div>
                    <div className="listeRep">{data.data.recipientsNames.map((recipient: any, index: number) => {
                        const lastReferent = index === data.data.recipientsNames.length - 1;
                        if (recipient.lastname == "anonyme") {
                            return lastReferent ? <span>Référent Anonyme </span> : <span>Référent Anonyme / </span>
                        }
                        return lastReferent ? (<span>{recipient.firstname + " " + recipient.lastname + " - " + recipient.company + " - " + labels[recipient.hierarchicalLink]}</span>) : (<span>{recipient.firstname + " " + recipient.lastname + " - " + recipient.company + " - " + labels[recipient.hierarchicalLink] + " / "}</span>)
                    })}</div>
                </div>
                <div className="footer" style={{ top: '1100px' }}>
                    <div className="lhhFooter">LHH</div>
                    <div style={{ marginLeft: '260px' }}>page 1/2</div>
                    <div className="coss" style={{ marginLeft: '160px' }}>Fourni par COSS by 5Feedback</div>
                </div>
            </div>
            {/* fin du document a print  */}
        </div>
    )
}
export default TemplatePdf1;