import dayjs from "dayjs";
import headerLogo from '../../assets/header.png'
import utc from 'dayjs/plugin/utc';

const labels: { [index: string]: string } = {
    1: 'Collègue',
    2: 'Manager',
    3: 'N-1',
    4: 'RH',
    5: 'Client',
};


dayjs.extend(utc);

const TemplatePdf2 = (data: any) => {
    const candidateInfo = data !== undefined && data.data.candidateInfo.firstname + " " + data.data.candidateInfo.lastname + " - " + data.data.candidateInfo.companyName + " - " + data.data.candidateInfo.jobTitle;

    return (
        <div
            id="divToPrint2"
            className="mt4"
            style={{ height: "1123px", width: "794px" }}
        >
            <div style={{ margin: "20px", backgroundColor: "#FCFCFE", height: '100%' }}>
                <div
                    style={{
                        backgroundColor: "#581F64",
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        marginBottom: "40px",
                    }}
                >
                    <div style={{ fontSize: "20px", fontWeight: "bold", color: "white", marginRight: "20%", marginLeft: "5%" }}>
                        LHH
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginRight: '15%' }}>
                        <div style={{ fontSize: "14px", color: "white", textAlign: "center" }}>
                            <div>RAPPORT DE PRISE DE REFERENCES</div>
                            <div>{candidateInfo}</div>
                            <div>Date d'édition : {dayjs.utc(data.data.candidateInfo.date).format("DD/MM/YYYY")}</div>
                        </div>
                    </div>
                    <img src={headerLogo} alt="logo" style={{ height: "80%", marginRight: '5%' }}></img>
                </div>
                {/* fin du header */}
                <div className="commentContainer">
                    <div className="commentTitle">Commentaires des Référents</div><br />
                    {data.data.recipientsComments.map((recipient: any, index: number) => (
                        <div>
                            <strong style={{ fontFamily: 'bold' }}>{
                                data.data && data.data.recipientsNames[index].lastname !== "anonyme" ? data.data && data.data.recipientsNames[index].lastname + " " + data.data.recipientsNames[index].firstname + "-" + data.data.recipientsNames[index].company + "-" + labels[data.data.recipientsNames[index].hierarchicalLink] + " :  " : "Référent Anonyme :  "
                            }</strong>
                            {recipient.comment}
                        </div>
                    ))}
                </div>
                <div className="commentContainer">
                    <div className="commentTitle">Commentaire du consultant</div>
                    <div className="comment" style={{ textAlign: "left" }}>{data.data.consultantInfo.comment}</div>
                </div>
                <div className="infoConsultant">
                    <div style={{ marginBottom: '3%' }} className="commentTitle">Coordonnées du consultant LHH</div>
                    <div className="">{data.data.consultantInfo.firstname + " " + data.data.consultantInfo.lastname}</div>
                    <div className="">{data.data.consultantInfo.email}</div>
                    <div className="">{data.data.consultantInfo.phone}</div>
                </div>
                <div className="footer" style={{ top: '1100px' }}>
                    <div className="lhhFooter">LHH</div>
                    <div style={{ marginLeft: '260px' }}>page 2/2</div>
                    <div className="coss" style={{ marginLeft: '160px' }}>Fourni par COSS by 5Feedback</div>
                </div>
            </div>

        </div>
    )
}
export default TemplatePdf2; 