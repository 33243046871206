import { useEffect, useState } from "react";
import { AutocompleteInput, Edit, email, required, SimpleForm, TextInput, useRecordContext } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";

const RecipientsEdit = () => {
  const [candidatures, setCandidatures] = useState([]);
  useEffect(() => {
    DataProvider.getList("consultant/candidatures").then((res) => {
      setCandidatures(res.data);
    });
  }, [])
  const candidaturesTab: Object[] = candidatures.map((e: any) => {
    return new Object({ id: e.id, name: e.job.company.name + "-" + e.job.title + "-" + e.candidate.lastname });
  })
  return (
    <Edit title={<RecipientsTitle />} redirect="list">
      <SimpleForm>
        <div style={{ display: 'flex', width: '100%' }}>
          <CustomTextInput variant="outlined" source="user.firstname" style={{ marginRight: '5%' }} label="Prénom du référent" validate={[required()]} />
          <CustomTextInput variant="outlined" source="user.lastname" label="Nom du référent" validate={[required()]} />
        </div>
        <CustomTextInput variant="outlined" source="user.email" label="Email du référent" validate={[email(), required()]} />
        <CustomTextInput variant="outlined" source="user.job_title" label="Fonction du référent" validate={[required()]} />
        <CustomTextInput variant="outlined" source="user.company.name" label="Entreprise du référent" validate={[required()]} />
        <CustomAutoCompleteInput variant="outlined" source="candidature.id" label="Candidature" choices={candidaturesTab} validate={[required()]} />
        <CustomAutoCompleteInput variant="outlined" validate={[required()]} source="hierarchical_link" label="Lien hiérarchique" choices={[
          { id: 1, name: 'Collègue' },
          { id: 2, name: 'Manager' },
          { id: 3, name: 'N-1' },
          { id: 4, name: 'RH' },
          { id: 5, name: 'Client' },
        ]} />
        <CustomAutoCompleteInput variant="outlined" label="Validation" source="validated" choices={[
          { id: 1, name: 'Accepté' },
          { id: 0, name: 'En attente' },
          { id: 2, name: 'Refusé' },
        ]} validate={required()} />
      </SimpleForm>
    </Edit>
  );
}
export default RecipientsEdit;

const RecipientsTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.email}` : ''}</span>
}