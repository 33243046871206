import { Datagrid } from "react-admin";


const CustomDatagrid = (props: any) => {
    return (
        <Datagrid {...props} size="small" sx={{
            "& .RaDatagrid-headerCell": {
                textTransform: 'uppercase',
                color: '#581F64',
                font: 'normal normal bold 12px/20px Arial'
            },
            "& .RaDatagrid-expandedPanel": {
                backgroundColor: '#F0EDED'
            },
            "& .MuiTable-root": {
                borderCollapse: 'inherit'
            },
            "& .RaDatagrid-expandIcon": {
                color: '#581F64'
            }

        }}>
            {props.children}
        </Datagrid>
    )
}
export default CustomDatagrid;