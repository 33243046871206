import { Button, useRedirect } from "react-admin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const BackButton = ({ urlData }: any) => {
    const redirect = useRedirect();
    const handleClick = (urlData: any) => {
        // eslint-disable-next-line no-restricted-globals
        redirect(urlData)
    }

    return (
        <Button label="Revenir à la liste" children={<ArrowBackIcon />} onClick={() => handleClick(urlData)} style={{ padding: '1em' }} />
    )
}
export default BackButton;