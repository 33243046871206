import { useRecordContext } from "react-admin";
import Chip from '@mui/material/Chip';

const ActiveTag = (props: any) => {
    const record = useRecordContext();
    if (props && props.source === "answer_status") {
        if (record && record.answer_status === false) {
            return <Chip label="Non réalisé" color="warning" variant="filled" />;
        } else if (record && record.answer_status === true) {
            return <Chip label="Réalisé" color="primary" variant="filled" />
        }
    } else if (props && props.source === "validated") {
        if (record && record.validated === 1) {
            return <Chip label="Accepté" color="primary" variant="filled" />;
        } else if (record && record.validated === 0) {
            return <Chip label="En attente" color="primary" variant="outlined" />
        } else if (record && record.validated === 2) {
            return <Chip label="Refusé" color="warning" variant="filled" />
        }
    } else if (record && record.status === 1) {
        return <Chip label="Archivé" color="warning" variant="filled" />;
    } else if (record && record.status === 0) {
        return <Chip label="Actif" color="primary" variant="filled" />
        // }else if(record.candidatureStatus && record.candidatureStatus === 0){
        //     return <Chip label="Inactif" color="error" variant="outlined" />
        // }else if(record.candidatureStatus && record.candidatureStatus === 1){
        //     return <Chip label="Actif" color="success" variant="outlined" />
    }
    return <span></span>

}
export default ActiveTag;