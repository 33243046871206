/* eslint-disable no-new-object */
import * as React from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import CheckedLogo from '../assets/check-symbol-4794.svg'
import Alert from '@mui/material/Alert';




import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    fetchUtils,
    Form,
    required,
    TextInput,
    useRedirect,
} from 'react-admin';

import Box from '@mui/material/Box';
import API_URL from '../utils/var';
import { Snackbar } from '@material-ui/core';

const httpClient = fetchUtils.fetchJson;

const ForgottenPassword = () => {
    const redirect = useRedirect();
    const waitTwoSeconds = () => new Promise(resolve => setTimeout(resolve, 2000));
    const [loading, setLoading] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [sent, setSent] = useState(false);
    const handleSubmit = (auth: FormValues) => {
        const url = `${API_URL}/forgottenpassword`;
        setLoading(true);
        httpClient(url, {
            method: 'POST',
            body: JSON.stringify(new Object({ email: auth.email }))
        }).then(() => {
            setLoading(false);
            setSent(true);
            setOpenToast(true);
            waitTwoSeconds();
            redirect('/login');
        });
        setLoading(false);
    }
    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundColor: '#581F64',

                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <>
                        <Box
                            sx={{
                                margin: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar sx={{ bgcolor: 'secondary.main' }}>
                                <LockIcon />
                            </Avatar>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                                color: theme => theme.palette.grey[500],
                            }}
                        >
                        </Box>
                        <Box sx={{ padding: '0 1em 1em 1em' }}>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    autoFocus
                                    source="email"
                                    label="email"
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                Réinitialiser le mot de passe
                            </Button>
                        </CardActions>
                    </>

                </Card>
            </Box>
            <Snackbar autoHideDuration={6000} open={openToast} onClose={() => setOpenToast(false)}>
                <Alert onClose={() => setOpenToast(false)} severity="success" sx={{ width: '100%' }}>
                    Un email de réinitialisation de mot de passe a été envoyé.
                </Alert>
            </Snackbar>
        </Form>
    )
}
export default ForgottenPassword;

interface FormValues {
    email?: string;
}