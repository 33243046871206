/* eslint-disable no-new-object */
// in src/users.tsx
import { useEffect, useState } from "react";
import { TextInput, useRecordContext, Edit, SimpleForm, AutocompleteInput, SelectArrayInput, regex } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import {
  required,
} from 'react-admin';
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";

export const JobsEdit = () => {
  const [companies, setCompanies] = useState([]);
  const [skillsPath, setSkillsPath] = useState([])
  const validateCode = regex(/[A-Za-z]{5}[0-9]{2}/, 'Le code doit contenir 2 chiffres et 5 lettres, exemple : 56TRtuy')
  useEffect(() => {
    DataProvider.getList("companies").then(res => setCompanies(res.data));
    DataProvider.getList("skills").then(res => setSkillsPath(res.data));
  }, [])
  const companiesTab: Object[] = companies.map((e: any) => {
    return new Object({ id: e.id, name: e.name });
  })
  const skillsPathTab: Object[] = skillsPath.map((e: any) => {
    return new Object({ id: e.id, name: e.title });
  })
  return (
    <Edit title={<JobsTitle />} redirect="list">
      <SimpleForm>
        {/* <TextInput source="id" disabled /> */}
        <CustomTextInput source="title" variant="outlined" />
        <CustomAutoCompleteInput variant="outlined" source="company.id" choices={companiesTab} label="Entreprise" validate={[required()]} />
        <SelectArrayInput variant="outlined" source="skills_path.linked_skills" sx={{ width: '25%' }} choices={skillsPathTab} label="Compétences" validate={[required()]} />
        <CustomAutoCompleteInput variant="outlined" source="status" validate={[required()]} label="Statut" choices={[
          { id: 0, name: 'Actif' },
          { id: 1, name: 'Archivé' },
        ]} />
        <CustomTextInput variant="outlined" source="code" label="Code du poste" validate={validateCode} />
      </SimpleForm>
    </Edit>
  )
};
export default JobsEdit;

const JobsTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.email}` : ''}</span>
};