import { AutocompleteInput, Edit, email, fetchUtils, required, SimpleForm, TextInput } from "react-admin";
import { useParams } from "react-router-dom"
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";
import BackButton from "./BackButton";
import image from '../../assets/header.png'
import API_URL from "../../utils/var";
import './Recipients.css'

import { useState, useEffect } from "react";


const httpClient = fetchUtils.fetchJson;

const EditRecipients = () => {
    const [jobData, setJobData] = useState<any>();
    let { candidatureId, token, id } = useParams();
    const array = []
    array.push(token, candidatureId);
    const url = `${API_URL}/candidate/${candidatureId}/${token}`;

    useEffect(() => {
        httpClient(url).then(({ body, json }) => (
            {
                data: json,
            })).then(res => setJobData(res.data));
    }, [])
    return (
        <>
            {jobData && (<>
                <div className='header'>
                    <div className='titleLhh'>LHH</div>
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                        <span style={{ color: 'white' }}>{jobData && jobData.candidate.firstname + " " + jobData.candidate.lastname + " - " + jobData.job.title + " - " + jobData.job.company.name}</span>
                    </div>
                    <img src={image} alt="logo" style={{ height: "80%" }}></img>
                </div>
                <Edit actions={<BackButton urlData={`/candidate/${token}/${candidatureId}`} />} disableAuthentication redirect={`/candidate/${token}/${candidatureId}`} resource="candidate" mutationOptions={{ meta: { token: token, candidatureId: candidatureId, id: id } }}>
                    <SimpleForm>
                        <CustomTextInput variant="outlined" source="user.firstname" label="Prénom du référent" validate={[required()]} />
                        <CustomTextInput variant="outlined" source="user.lastname" label="Nom du référent" validate={[required()]} />
                        <CustomTextInput variant="outlined" source="user.email" label="Email du référent" validate={[email(), required()]} />
                        <CustomTextInput variant="outlined" source="user.company.name" label="Entreprise" validate={[required()]} />
                        <CustomTextInput variant="outlined" source="user.job_title" label="Fonction du référent" validate={[required()]} />
                        <CustomTextInput variant="outlined" source="user.phone" label="Téléphone du référent" validate={[required()]} />
                        <CustomAutoCompleteInput variant="outlined" source="hierarchical_link" label='Lien hiérarchique' choices={[
                            { id: 1, name: 'Collègue' },
                            { id: 2, name: 'Manager' },
                            { id: 3, name: 'N-1' },
                            { id: 4, name: 'RH' },
                            { id: 5, name: 'Client' },
                        ]} />
                    </SimpleForm>
                </Edit>
            </>)}
        </>
    )
}

export default EditRecipients;