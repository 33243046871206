import {
    AutocompleteInput,
    Create,
    email,
    fetchUtils,
    ListButton,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
} from "react-admin";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import BackButton from "./BackButton";
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";
import image from '../../assets/header.png'
import { useState, useEffect } from "react";
import API_URL from "../../utils/var";
import './Recipients.css'
import { useFormContext } from 'react-hook-form';


const httpClient = fetchUtils.fetchJson;



const CreateRecipients = () => {

    const [jobData, setJobData] = useState<any>();
    let { candidatureId, token, id } = useParams();
    const array = []
    array.push(token, candidatureId);
    const url = `${API_URL}/candidate/${candidatureId}/${token}`;
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data: any) => {
        notify(`Ajouté avec succès`);
        redirect(`/candidate/${token}/${candidatureId}/create`);
        // eslint-disable-next-line no-restricted-globals
        history.go(0)
    };
    const PostCreateToolbar = () => {
        const notify = useNotify();
        const { reset } = useFormContext();

        return (
            <Toolbar>
                <SaveButton
                    type="button"
                    label="Enregistrer"
                    variant="contained"
                    style={{ marginRight: '5%' }}
                    mutationOptions={{
                        onSuccess: () => {
                            redirect(`/candidate/${token}/${candidatureId}`)
                            notify('ra.notification.created', {
                                type: 'info',
                                messageArgs: { smart_count: 1 },
                            });
                        },
                    }}
                />
                <SaveButton
                    type="button"
                    label="Enregistrer et ajouter un autre référent"
                    variant="outlined"
                    mutationOptions={{
                        onSuccess: () => {
                            reset();
                            window.scrollTo(0, 0);
                            notify('ra.notification.created', {
                                type: 'info',
                                messageArgs: { smart_count: 1 },
                            });
                        },
                    }}
                />

            </Toolbar>
        );
    };


    useEffect(() => {
        httpClient(url).then(({ body, json }) => (
            {
                data: json,
            })).then(res => setJobData(res.data));
    }, [])
    return (
        <>
            {jobData && (<>
                <div className='header'>
                    <div className='titleLhh'>LHH</div>
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                        <span style={{ color: 'white' }}>{jobData && jobData.candidate.firstname + " " + jobData.candidate.lastname + " - " + jobData.job.title + " - " + jobData.job.company.name}</span>
                    </div>
                    <img src={image} alt="logo" style={{ height: "80%" }}></img>
                </div>
                <div style={{ padding: '2em' }}>
                    <Create
                        resource="candidate/recipients"
                        disableAuthentication
                        redirect={`/candidate/${token}/${candidatureId}`}
                        mutationOptions={{ onSuccess, meta: { token: token, candidatureId: candidatureId, id: id } }}
                        actions={<BackButton urlData={`/candidate/${token}/${candidatureId}`} />}
                    >
                        <SimpleForm toolbar={<PostCreateToolbar />}>
                            <CustomTextInput
                                source="firstname"
                                label="Prénom du référent"
                                validate={required()}
                                variant="outlined"
                            />
                            <CustomTextInput
                                source="lastname"
                                label="Nom du référent"
                                validate={required()}
                                variant="outlined"
                            />
                            <CustomTextInput
                                source="email"
                                label="Email du référent"
                                validate={[email(), required()]}
                                variant="outlined"
                            />
                            <CustomTextInput
                                source="recipient_job_title"
                                label="Fonction du référent"
                                validate={required()}
                                variant="outlined"
                            />
                            <CustomTextInput
                                source="phone"
                                label="Téléphone du référent"
                                validate={required()}
                                variant="outlined"
                            />
                            <CustomTextInput variant="outlined" source="company_name" label="Entreprise" validate={[required()]} />
                            <CustomAutoCompleteInput
                                variant="outlined"
                                source="hierarchical_link"
                                label="Lien hiérarchique"
                                choices={[
                                    { id: 1, name: "Collègue" },
                                    { id: 2, name: "Manager" },
                                    { id: 3, name: "N-1" },
                                    { id: 4, name: "RH" },
                                    { id: 5, name: "Client" },
                                ]}
                                validate={required()}
                            />
                        </SimpleForm>
                    </Create>
                </div>
            </>)}
        </>
    );
};
export default CreateRecipients;
