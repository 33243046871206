import { useEffect, useState } from "react";
import { AutocompleteInput, Button, DateField, EditButton, fetchUtils, List, SearchInput, TextField, WithRecord } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import HierarchicalLink from "../../components/fields/HierarchicalLink";
import RecipientsValidation from "../../components/fields/RecipientsValidation";
import API_URL from "../../utils/var";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import FeedbackRequest from "../../components/buttons/FeedbackRequest";
import RecipientsDetails from "../../components/subtable/RecipientsDetails";
import SendEmailFeedback from "../../components/buttons/SendEmailFeeback";
import CustomDatagrid from "../../components/CustomDatagrid/CustomDatagrid";
import ActiveTag from "../../components/fields/ActiveTag";


const httpClient = fetchUtils.fetchJson;

const RecipientsList = () => {
  const [companies, setCompanies] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState<any[]>();
  const [recipients, setRecipients] = useState([]);
  const [value, setValue] = useState("template_1");
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  const sendFeedbackRequest = () => {
    let recipientsToSend: any = [];
    recipients.forEach((r: any) => {
      recipientsToSend.push(r)
    })
    const url = `${API_URL}/consultant/recipients/send/email`;
    const token = localStorage.getItem('access_token');
    let headers = null;
    headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })
    httpClient(url, {
      method: 'POST',
      body: JSON.stringify(new Object({ recipients: recipientsToSend, template: value })),
      headers: headers,
    }).then(() => {
      setOpen(false)
      setRecipients([]);
    });
  }
  const handleClickOpen = async (record: any) => {
    const recipientsToSend = recipients.concat(record.id);
    setRecipients(recipientsToSend);
    const url = `${API_URL}/consultant/recipients/emails`;
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
    };
    const token = localStorage.getItem('access_token');
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    await httpClient(url, options).then(({ json }) => (
      {
        data: json,
      })).then(res => {
        setTemplates(res.data);
      });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRecipients([]);
  };
  // async function Anonymised(record: any) {
  //   const options = {
  //     headers: new Headers({ Accept: 'application/json' }),
  //   };
  //   const token = localStorage.getItem('access_token');
  //   if (token) {
  //     options.headers.set('Authorization', `Bearer ${token}`);
  //   }
  //   let headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token}`
  //   })
  //   await httpClient(`${API_URL}/consultant/recipients/anonymise/${record.id}`, {
  //     method: 'POST',
  //     headers: headers,
  //   })
  // }
  useEffect(() => {
    //Appel API pour les consultants
    const url = `${API_URL}/consultants`
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
    };
    const token = localStorage.getItem('access_token');
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    httpClient(url, options).then(({ json }) => (
      {
        data: json.results,
      })).then(res => setConsultant(res.data))

    //Get la liste des clients 
    DataProvider.getList("companies").then(res => setCompanies(res.data));
  }, [])
  // const isSmall = useMediaQuery((theme : any) => theme.breakpoints.down("sm"));
  return (
    <>
      <List filters={CompanyListBuilder(companies, consultant)} >
        <CustomDatagrid bulkActionButtons={<FeedbackRequest />} expand={
          <RecipientsDetails />
        }>
          <RecipientsValidation />
          <TextField source="user.lastname" label="Nom du référent" sortBy="recipient_lastname" />
          <TextField source="user.firstname" label="Prénom du référent" sortBy="recipient_firstname" />
          <WithRecord render={record => <span>{record.candidature.job.consultant.firstname + " " + record.candidature.job.consultant.lastname}</span>} label="Consultant Responsable"></WithRecord>
          <TextField source="user.job_title" label="Fonction du référent" sortBy="recipient_job_title" />
          <TextField source="user.company.name" label="Entreprise du référent" sortBy="company_name" />
          <HierarchicalLink source="hierarchical_link" label="Lien hiérarchique" />
          <WithRecord render={record => <span>{record.candidature.candidate.firstname + " " + record.candidature.candidate.lastname}</span>} label="Candidat"></WithRecord>
          <TextField source="candidature.job.title" label="Nom du poste" sortBy="job_title" />
          <TextField source="candidature.job.company.name" label="Client" sortBy="client" />
          <ActiveTag source="validated" label="Validation (par le consultant)" sortBy="validation_status" />
          <ActiveTag source="answer_status" label="Statut (réponse du référent)" sortBy="answer_status" />
          <DateField source="last_reminded_at" label="Date de dernière relance" sortBy="last_reminded_at" />
          <WithRecord render={record =>
            <SendEmailFeedback record={record} />
          } ></WithRecord>
          <WithRecord render={record =>
            <EditButton label="" disabled={!(localStorage.getItem("userId") == record.candidature.job.consultant.id)} />
          }></WithRecord>
          {/* <DeleteWithConfirmButton confirmContent="Vous êtes sur de vouloir supprimer ce répondant ?" style={{ marginRight: '10%' }} /> */}
        </CustomDatagrid>
      </List>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Demande de Feedback</DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="template_1" control={<Radio />} label={
              <Card style={{ margin: '1em' }}>
                <div dangerouslySetInnerHTML={{ __html: templates && templates[0][0].subject }}></div>
                <div dangerouslySetInnerHTML={{ __html: templates && templates[0][0].body }}></div>
              </Card>
            } />
            <FormControlLabel value="template_2" control={<Radio />} label={
              <Card style={{ margin: '1em' }}>
                <div dangerouslySetInnerHTML={{ __html: templates && templates[0][1].subject }}></div>
                <div dangerouslySetInnerHTML={{ __html: templates && templates[0][1].body }}></div>
              </Card>
            } />
          </RadioGroup>

        </DialogContent>
        <DialogActions>
          <Button onClick={sendFeedbackRequest} label="Envoyer" />
          <Button onClick={handleClose} label="Annuler" />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default RecipientsList;

function CompanyListBuilder(companies: any, consultant: any) {
  const companiesTab: Object[] = companies.map((e: any) => {
    return new Object({ id: e.id, name: e.name });
  })
  const consultantTab: Object[] = consultant.map((e: any) => {
    return new Object({ id: e.id, name: e.lastname + " " + e.firstname });
  })
  const companyFilter = [
    <SearchInput source="q" alwaysOn />,
    <AutocompleteInput source="clientId" label="Client" choices={companiesTab} />,
    <AutocompleteInput source="selected_consultant" label="Consultant" choices={consultantTab} defaultValue={localStorage.getItem("userId")} />,
    <AutocompleteInput source="validation_status" label="Statut de validation" choices={[
      { id: 0, name: 'Actif' },
      { id: 1, name: 'Archivé' },
      { id: 2, name: 'Tous' },
    ]} />,
    <AutocompleteInput source="answer_status" label="Statut de réponse" choices={[
      { id: 0, name: 'En attente' },
      { id: 1, name: 'Répondu' },
      { id: 2, name: 'Ignoré' },
    ]} />,
    <AutocompleteInput alwaysOn source="candidature_status" label="Statut de candidature" choices={[
      { id: 0, name: 'Actif' },
      { id: 1, name: 'Archivé' },
    ]} />,
  ];

  return companyFilter;

}