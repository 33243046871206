import { useEffect, useState } from "react";
import { fetchUtils } from "react-admin";
import { useParams } from 'react-router-dom';
import API_URL from "../../utils/var";
import FeedbackForm from "../../components/form/FeedbackForm";

const httpClient = fetchUtils.fetchJson;

const FeedbackAnswer = () => {
    const { feedbackId, token } = useParams();
    const [feedbacks, setFeedbacks] = useState<any[]>([]);
    const locale = navigator.language;
    const [answered, setAnswered] = useState(false);

    useEffect(() => {
        const url = `${API_URL}/feedback/${locale}/${feedbackId}/${token}`;
        httpClient(url)
            .then(({ body, json }) => ({ data: json.feedbacks }))
            .then(res => setFeedbacks(res.data))
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    // gérer l'erreur 403 ici
                    setAnswered(true)
                }
            });
    }, [])
    return (
        <FeedbackForm data={feedbacks} answered={answered} />

    )
}
export default FeedbackAnswer;