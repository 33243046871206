import { Button, DeleteWithConfirmButton, useRecordContext, useRedirect } from "react-admin";
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';



const TagCandidateStatus = (props: any) => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const edit = (props: any) => {
        const url = `/candidate/${props.props[0]}/${props.props[1]}/edit/${record.id}`;
        redirect(url);
    }
    switch (record.validated) {
        case 0:
            return (
                <>
                    <Chip label="En attente" color="primary" variant="outlined" style={{ marginRight: '10%' }} />
                    <Button onClick={() => edit(props)} children={<EditIcon />} label="" />
                    <DeleteWithConfirmButton label="" confirmContent="Vous êtes sur de vouloir supprimer ce répondant ?" redirect="false" style={{ marginRight: '10%' }} mutationOptions={{ meta: { id: props.props[1], token: props.props[0] } }} />

                </>
            );

        case 1:
            return <Chip label="Accepté" color="primary" variant="outlined" />;

        case 2:
            return <Chip label="Refusé" color="warning" variant="filled" />;
    }
    return <span></span>
}
export default TagCandidateStatus;