/* eslint-disable no-new-object */
// in src/users.tsx
import { useEffect, useState } from "react";
import { SimpleForm, AutocompleteInput, required, Create, email, TextInput } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";
import { CandidaturesList } from "./CandidaturesList";
export const CandidaturesCreate = () => {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    DataProvider.getList("consultant/jobs").then(res => setJobs(res.data));
  }, [])
  const jobsTab: Object[] = jobs.map((e: any) => {
    return new Object({ id: e.id, name: e.code + " - " + e.title + " - " + e.company.name });
  })
  return (
    <Create redirect="list">
      <SimpleForm>
        <div style={{ display: 'flex', width: '100%' }}>
          <CustomTextInput variant="outlined" source="lastname" style={{ marginRight: '5%' }} validate={required()} label="Nom" />
          <CustomTextInput variant="outlined" source="firstname" validate={required()} label="Prénom" />
        </div>
        <CustomTextInput variant="outlined" source="email" type="email" validate={[email(), required()]} label="Email" />
        <CustomTextInput variant="outlined" source="phone" label="Téléphone" />
        <CustomAutoCompleteInput variant="outlined" source="job_id" choices={jobsTab} validate={required()} label="Poste" />
      </SimpleForm>
    </Create>
  )
};
export default CandidaturesCreate;