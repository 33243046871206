import { defaultTheme, warning } from "react-admin"

const lhhTheme = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: '#581F64',
            contrastText: '#FFFFFF', // remplacez par la couleur souhaitée
        },
        warning: {
            main: '#581F6433',
            contrastText: '#581F64',
        },
        secondary: {
            main: '#FFFFFF',
            contrastText: '#581F64',
        },
        sidebar: {
            main: '#581F64',
            contrastText: '#FFFFFF',
        },
        overrides: {
            MuiTableRow: {
                hover: {
                    "&:hover": {
                        backgroundColor: "red !important"
                    }
                }
            }
        }

    }
}
export default lhhTheme;