import { Button, fetchUtils } from "react-admin";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import API_URL from "../../utils/var";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import './Buttons.css'
import { Grid, Snackbar } from "@material-ui/core";
import Alert from '@mui/material/Alert';



const httpClient = fetchUtils.fetchJson;

const SendEmailFeedback = ({ record }: any) => {
    const [open, setOpen] = useState(false);
    const [templates, setTemplates] = useState<any[]>();
    const [recipients, setRecipients] = useState([]);
    const [value, setValue] = useState("template_1");
    const [openToast, setOpenToast] = useState(false);
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };
    const sendFeedbackRequest = () => {
        let recipientsToSend: any = [];
        recipients.forEach((r: any) => {
            recipientsToSend.push(r)
        })
        const url = `${API_URL}/consultant/recipients/send/email`;
        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        httpClient(url, {
            method: 'POST',
            body: JSON.stringify(new Object({ recipients: recipientsToSend, template: value })),
            headers: headers,
        }).then(() => {
            setOpen(false)
            setOpenToast(true);
            setRecipients([]);
        });
    }
    const handleClickOpen = async (record: any) => {
        const recipientsToSend = recipients.concat(record.id);
        setRecipients(recipientsToSend);
        const url = `${API_URL}/consultant/recipients/emails`;
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        await httpClient(url, options).then(({ json }) => (
            {
                data: json,
            })).then(res => {
                setTemplates(res.data);
            });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setRecipients([]);
    };
    return (
        <>
            <Button children={<SendIcon />} onClick={() => handleClickOpen(record)} disabled={record.validated === 1 && !record.answer_status ? false : true}></Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" >
                <DialogTitle className="title">Demande de Feedback</DialogTitle>
                <DialogContent className="contentDialog">
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={6}>
                                <FormControlLabel value="template_1" control={<Radio />} labelPlacement='bottom' label={
                                    <Card className="cardEmail">
                                        <div dangerouslySetInnerHTML={{ __html: "Sujet : " + (templates && templates[0][0].subject) }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: templates && templates[0][0].body }}></div>
                                    </Card>
                                } />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel labelPlacement='bottom' value="template_2" control={<Radio />} label={
                                    <Card className="cardEmail">
                                        <div dangerouslySetInnerHTML={{ __html: "Sujet : " + (templates && templates[0][1].subject) }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: templates && templates[0][1].body }}></div>
                                    </Card>
                                } />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={sendFeedbackRequest} color='primary' label="Envoyer" />
                    <Button onClick={handleClose} color='primary' label="Annuler" />
                </DialogActions>
            </Dialog>
            <Snackbar autoHideDuration={6000} open={openToast} onClose={() => setOpenToast(false)}>
                <Alert onClose={() => setOpenToast(false)} severity="success" sx={{ width: '100%' }}>
                    Email envoyé au référent avec succès.
                </Alert>
            </Snackbar>
        </>
    )
}
export default SendEmailFeedback;