import { List, TextField, useRecordContext, WithRecord } from "react-admin";
import PdfExport from "../buttons/PdfExport";
import SendEmailFeedback from "../buttons/SendEmailFeeback";
import CustomDatagrid from "../CustomDatagrid/CustomDatagrid";
import ActiveTag from "../fields/ActiveTag";
import HierarchicalLink from "../fields/HierarchicalLink";

const MandatsDetails = ({ id }: { id: number }) => {
    const record = useRecordContext();
    return (
        <List hasCreate={false} pagination={false} exporter={false} key={id} >
            <CustomDatagrid bulkActionButtons={false} isRowExpandable={(row: any) => row.recipients.length > 0} data={record.candidatures} expand={
                <WithRecord render={record =>
                    <List hasCreate={false} pagination={false} exporter={false} key={1} >
                        <CustomDatagrid bulkActionButtons={false} data={record.recipients}>
                            <TextField source="user.lastname" label="Nom du référent" />
                            <TextField source="user.firstname" label="Prénom du référent" />
                            <TextField source="user.job_title" label="Fonction du référent" />
                            <TextField source="user.company.name" label="Entreprise du référent" />
                            <HierarchicalLink source="hierarchical_link" label="Lien avec le candidat" />
                            <ActiveTag props={"validated"} source="validated" label="Statut validation par le consultant" />
                            <ActiveTag source="answer_status" label="Statut evaluation par le référent" />
                            <WithRecord render={record =>
                                <SendEmailFeedback record={record} />
                            } ></WithRecord>
                        </CustomDatagrid>
                    </List>
                }></WithRecord>
            }>
                <TextField source="candidate.lastname" label="Nom" />
                <TextField source="candidate.firstname" label="Prénom" />
                <ActiveTag source="status" label="Statut du candidat" />
                <WithRecord render={record => <span>{record.answers_count + "/" + record.total_count}</span>} label="Nombre d'évaluations"></WithRecord>
                <WithRecord render={record =>
                    <PdfExport candidature={record} nbrAnswer={record.answers_count} />
                }></WithRecord>
            </CustomDatagrid>
        </List>
    )
}
export default MandatsDetails;