/* eslint-disable no-new-object */
// in src/users.tsx
import { useEffect, useState } from "react";
import { useRecordContext, Edit, SimpleForm, required, email } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
export const CandidaturesEdit = () => {
  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    DataProvider.getList("users").then(res => setUsers(res.data));
    DataProvider.getList("consultant/jobs").then(res => setJobs(res.data));
  }, [])
  const jobsTab: Object[] = jobs.map((e: any) => {
    return new Object({ id: e.id, name: e.code + " - " + e.title + " - " + e.company.name });
  })
  return (
    <Edit title={<CandidaturesTitle />} redirect="list">
      <SimpleForm>
        <div style={{ display: 'flex', width: '100%' }}>
          <CustomTextInput variant="outlined" source="candidate.lastname" style={{ marginRight: '5%' }} validate={required()} label="Nom" />
          <CustomTextInput variant="outlined" source="candidate.firstname" validate={required()} label="Prénom" />
        </div>
        <CustomTextInput variant="outlined" source="candidate.email" type="email" validate={[email(), required()]} label="Email" />
        <CustomTextInput variant="outlined" source="candidate.phone" label="Téléphone" />
        <CustomAutoCompleteInput variant="outlined" source="job.id" choices={jobsTab} label="Poste" validate={required()} />
        <CustomAutoCompleteInput variant="outlined" source="status" label="Statut" choices={[
          { id: 0, name: 'Active' },
          { id: 1, name: 'Archived' },
        ]} validate={required()} />
      </SimpleForm>
    </Edit>
  )
};
export default CandidaturesEdit;

const CandidaturesTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `${record.email}` : ''}</span>
};