import { Sidebar, useSidebarState } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    sidebar: {
        backgroundColor: '#581F64',
    },
}));

const MySideBar = (props: any) => {
    const classes = useStyles();
    const [open] = useSidebarState();
    return (
        <Sidebar className={classes.sidebar} {...props} sx={{
            width: open ? 200 : 50,
            transition: theme =>
                theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            ".RaMenuItemLink-active": {
                color: '#FFFFFF !important',
                opacity: '1 !important'
            },
            ".MuiListItemIcon-root": {
                color: '#FFFFFF !important',
                opacity: '0.6 !important'
            },
            ".css-6fdu7w": {
                color: '#FFFFFF ',
                opacity: '0.6 '
            },
            ".css-w35v65": {
                color: '#FFFFFF ',
                opacity: '0.6 '
            }

        }} />
    )
}
export default MySideBar;