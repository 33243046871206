/* eslint-disable no-new-object */
import { useEffect, useState } from "react";
import { AutocompleteInput, Create, email, required, SimpleForm, TextInput } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";

const RecipientsCreate = () => {
  const [candidatures, setCandidatures] = useState([]);
  useEffect(() => {
    DataProvider.getList("consultant/candidatures").then((res) => {
      setCandidatures(res.data)
    });
  }, [])
  const candidaturesTab: Object[] = candidatures.map((e: any) => {
    return new Object({ id: e.id, name: e.job.company.name + "-" + e.job.title + "-" + e.candidate.lastname });
  })
  return (
    <Create redirect="list">
      <SimpleForm>
        <div style={{ display: 'flex', width: '100%' }}>
          <CustomTextInput variant="outlined" source="firstname" style={{ marginRight: '5%' }} label="Prénom du référent" validate={required()} />
          <CustomTextInput variant="outlined" source="lastname" label="Nom du référent" validate={required()} />
        </div>
        <CustomTextInput variant="outlined" source="email" style={{ width: '29%' }} label="Email du référent" validate={[email(), required()]} />
        <CustomTextInput variant="outlined" source="recipient_job_title" label="Fonction du référent" validate={[required()]} />
        <CustomTextInput variant="outlined" source="company_name" label="Nom de l'entreprise" validate={[required()]} />
        <CustomAutoCompleteInput variant="outlined" source="candidature_id" label="Candidature" choices={candidaturesTab} />
        <CustomAutoCompleteInput variant="outlined" source="hierarchical_link" label="Lien hiérarchique" choices={[
          { id: 1, name: 'Collègue' },
          { id: 2, name: 'Manager' },
          { id: 3, name: 'N-1' },
          { id: 4, name: 'RH' },
          { id: 5, name: 'Client' }
        ]} validate={required()} />
        {/* <BooleanInput source="receive_feedbacks" label="Je veux être notifié quand le référent a évalué le candidat" validate={required()} defaultChecked /> */}
      </SimpleForm>
    </Create>
  )
};

export default RecipientsCreate;