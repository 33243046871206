import { AuthProvider, fetchUtils } from 'react-admin';
import API_URL, { URL_SERVER } from '../utils/var';

const httpClient = fetchUtils.fetchJson;

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        const bodyParams = new FormData();
        const secret = await fetch(new Request(API_URL + '/credentials', {
            method: 'GET'
        }))
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((auth: any) => {
                bodyParams.append("username", username)
                bodyParams.append("password", password)
                bodyParams.append("client_id", auth.clientId)
                bodyParams.append("client_secret", auth.clientSecret)
                bodyParams.append("grant_type", "password")
                const request = new Request(URL_SERVER + '/oauth/v2/token', {
                    method: 'POST',
                    body: bodyParams,
                });
                return fetch(request)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then((auth: any) => {
                        localStorage.setItem('access_token', auth.access_token);
                    })
                    .catch(() => {
                        throw new Error('Login or password')
                    });
            })
            .catch(() => {
                throw new Error('Network error')
            });
        return secret;

    },
    logout: () => {
        localStorage.removeItem('access_token');
        return Promise.resolve();
    },
    checkAuth: (props: any) => {
        return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
    },
    checkError: (error) => {
        const status = error.status;
        // if (status === 401 || status === 403) {
        //     localStorage.removeItem('access_token');
        //     return Promise.reject();
        // }
        return Promise.resolve();
    },
    getIdentity: async () => {
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = await localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        const user = await httpClient(`${API_URL}/logged/user`, options).then(({ json }) => ({ data: json }));
        //PASSER LE LOCALSTORAGE EN STORAGE REACT ADMIN 
        localStorage.setItem('userId', user.data.id);
        return Promise.resolve({
            id: 'user',
            fullName: user.data.firstname + " " + user.data.lastname,
        })
    },
    getPermissions: () => Promise.resolve(''),
}

export default authProvider;