/* eslint-disable no-new-object */
/* eslint-disable react/no-danger-with-children */
import { useState } from "react";
import { Button, fetchUtils, useDeleteMany, useListContext } from "react-admin";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import API_URL from "../../utils/var";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Snackbar } from "@material-ui/core";
import Alert from '@mui/material/Alert';



const httpClient = fetchUtils.fetchJson;

const FeedbackRequest = ({ resource }: any) => {
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);
    const [templates, setTemplates] = useState<any[]>();
    const [value, setValue] = useState("template_1");
    const [openToast, setOpenToast] = useState(false);
    const [deleteMany, { data, isLoading, error }] = useDeleteMany(
        resource,
        { ids: selectedIds }
    );

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };
    //Envoie de la demande de Feedback aux recipients actifs
    const sendFeedbackRequest = () => {
        const url = `${API_URL}/consultant/recipients/send/email`;
        const token = localStorage.getItem('access_token');
        let headers = null;
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
        httpClient(url, {
            method: 'POST',
            body: JSON.stringify(new Object({ recipients: selectedIds, template: value })),
            headers: headers,
        }).then(() => {
            setOpenToast(true);
            setOpen(false);
        });
    }
    const handleClickDelete = async () => {
        await deleteMany();
    }
    const handleClickOpen = async () => {
        const url = `${API_URL}/consultant/recipients/emails`;
        const options = {
            headers: new Headers({ Accept: 'application/json' }),
        };
        const token = localStorage.getItem('access_token');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }
        await httpClient(url, options).then(({ json }) => (
            {
                data: json,
            })).then(res => {
                setTemplates(res.data);
            });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button label="Solliciter les référents" onClick={handleClickOpen} children={<SendIcon />} color='primary' style={{ backgroundColor: '#FFFFFF' }} />
            <Button label="Supprimer" onClick={handleClickDelete} children={<DeleteIcon />} />
            <Dialog open={open} onClose={handleClose} maxWidth="lg" >
                <DialogTitle className="title">Demande de Feedback</DialogTitle>
                <DialogContent className="contentDialog">
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={6}>
                                <FormControlLabel value="template_1" control={<Radio />} labelPlacement='bottom' label={
                                    <Card className="cardEmail">
                                        <div dangerouslySetInnerHTML={{ __html: "Sujet : " + (templates && templates[0][0].subject) }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: templates && templates[0][0].body }}></div>
                                    </Card>
                                } />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel labelPlacement='bottom' value="template_2" control={<Radio />} label={
                                    <Card className="cardEmail">
                                        <div dangerouslySetInnerHTML={{ __html: "Sujet : " + (templates && templates[0][1].subject) }}></div>
                                        <div dangerouslySetInnerHTML={{ __html: templates && templates[0][1].body }}></div>
                                    </Card>
                                } />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={sendFeedbackRequest} color='primary' label="Envoyer" />
                    <Button onClick={handleClose} color='primary' label="Annuler" />
                </DialogActions>
            </Dialog>
            <Snackbar autoHideDuration={6000} open={openToast} onClose={() => setOpenToast(false)}>
                <Alert onClose={() => setOpenToast(false)} severity="success" sx={{ width: '100%' }}>
                    Email envoyé au référent avec succès.
                </Alert>
            </Snackbar>
        </>
    )
}
export default FeedbackRequest;