/* eslint-disable no-new-object */
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { List, Datagrid, TextField, SearchInput, DateField, AutocompleteInput, DeleteWithConfirmButton, CloneButton, WithRecord, EditButton, fetchUtils, } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomDatagrid from "../../components/CustomDatagrid/CustomDatagrid";
import ActiveTag from "../../components/fields/ActiveTag";
import API_URL from "../../utils/var";

const httpClient = fetchUtils.fetchJson;

export const JobsList = () => {
  const [companies, setCompanies] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const userId = localStorage.getItem('userId')

  const handleClick = () => {
    localStorage.setItem("duplication", "true");
  }
  useEffect(() => {
    const url = `${API_URL}/consultants`
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
    };
    const token = localStorage.getItem('access_token');
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    httpClient(url, options).then(({ json }) => (
      {
        data: json.results,
      })).then(res => setConsultant(res.data));
    DataProvider.getList("companies").then(res => setCompanies(res.data));
  }, [])
  // const isSmall = useMediaQuery((theme : any) => theme.breakpoints.down("sm"));
  return (
    <List filters={CompanyListBuilder(companies, consultant)} >
      <CustomDatagrid>
        <TextField source="title" label="Nom du poste" sortBy="job_title" />
        <TextField source="code" label="Code du poste" sortBy="code" />
        <TextField source="company.name" label="Client" sortBy="company_name" />
        <WithRecord label="Compétences" render={record => {
          return (
            <div>
              {record.skills_path && record.skills_path.linked_skills && record.skills_path.linked_skills.map((linkedSkill: any) => {
                if (linkedSkill.skill) {
                  return (
                    <Chip key={linkedSkill.skill.id} label={linkedSkill.skill.title} style={{ margin: '1%' }}></Chip>
                  )
                }

              })}
            </div>
          )
        }} />
        <TextField source="consultant.business_unit.name" label="Business Unit" sortBy="business_unit" />
        <WithRecord render={record => <span>{record.consultant.firstname + " " + record.consultant.lastname}</span>} label="Consultant Responsable"></WithRecord>
        <ActiveTag source="status" label="Statut du poste" sortBy="status" />
        <DateField source="created_at" label="Créé le" sortBy="created_at" />
        <DateField source="updated_at" label="Mis à jour" sortBy="updated_at" />
        <CloneButton onClick={handleClick} label="" />
        <WithRecord render={record => <EditButton label="" disabled={userId === record.consultant.id.toString() ? false : true} />}></WithRecord>
        <WithRecord render={record => <DeleteWithConfirmButton label="" disabled={userId === record.consultant.id.toString() ? false : true} confirmContent="Vous êtes sur de vouloir supprimer ce poste ?" style={{ marginRight: '10%' }} />}></WithRecord>
      </CustomDatagrid>
    </List>
  );
};
export default JobsList;

function CompanyListBuilder(companies: any, consultant: any) {
  const companiesTab: Object[] = companies.map((e: any) => {
    return new Object({ id: e.id, name: e.name });
  })
  const consultantTab: Object[] = consultant.map((e: any) => {
    return new Object({ id: e.id, name: e.lastname + " " + e.firstname });
  })
  const JobFilter = [
    <SearchInput source="q" alwaysOn />,
    <AutocompleteInput source="selected_consultant" label="Consultant" choices={consultantTab} defaultValue={localStorage.getItem("userId")} />,
    <AutocompleteInput source="status" label="Statut" choices={[
      { id: 0, name: 'Actif' },
      { id: 1, name: 'Archivé' },
      { id: 2, name: 'Tous' },
    ]} />,
    <AutocompleteInput source="clientId" label="Client" choices={companiesTab} />
  ];
  return JobFilter;

}