/* eslint-disable no-new-object */
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { List, TextField, SearchInput, AutocompleteInput, WithRecord, fetchUtils } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomDatagrid from "../../components/CustomDatagrid/CustomDatagrid";
import ActiveTag from "../../components/fields/ActiveTag";
import MandatsDetails from "../../components/subtable/MandatsDetails";
import API_URL from "../../utils/var";

const httpClient = fetchUtils.fetchJson;

export const JobsList = () => {
  const [companies, setCompanies] = useState([]);
  const [consultant, setConsultant] = useState([]);

  useEffect(() => {
    const url = `${API_URL}/consultants`
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
    };
    const token = localStorage.getItem('access_token');
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`);
    }
    httpClient(url, options).then(({ json }) => (
      {
        data: json.results,
      })).then(res => setConsultant(res.data));
    DataProvider.getList("companies").then(res => setCompanies(res.data));
  }, [])
  // const isSmall = useMediaQuery((theme : any) => theme.breakpoints.down("sm"));
  return (
    <List filters={CompanyListBuilder(companies, consultant)}>
      <CustomDatagrid bulkActionButtons={false} expand={<MandatsDetails id={3} />} isRowExpandable={(row: any) => row.candidatures.length > 0} >
        <TextField source="title" label="Nom du poste" sortBy="job_title" />
        <TextField source="company.name" label="Client" sortBy="company_name" />
        <WithRecord label="Compétences" render={record => (
          <div>
            {record.skills_path.linked_skills.map((skill: any) => {
              return (
                <Chip key={skill.skill.id} label={skill.skill.title} style={{ margin: '1%' }}></Chip>
              )
            })}
          </div>
        )} />
        <TextField source="consultant.business_unit.name" label="Business Unit" sortBy="business_unit" />
        <WithRecord render={record => <span>{record.consultant.firstname + " " + record.consultant.lastname}</span>} label="Consultant Responsable"></WithRecord>
        <ActiveTag source="status" label="Statut du poste" sortBy="status" />
        <WithRecord label="Candidats" render={record => <span>{record.candidatures.length} Candidats</span>}></WithRecord>
      </CustomDatagrid>
    </List>
  );
};
export default JobsList;

function CompanyListBuilder(companies: any, consultant: any) {
  const companiesTab: Object[] = companies.map((e: any) => {
    return new Object({ id: e.id, name: e.name });
  })
  const consultantTab: Object[] = consultant.map((e: any) => {
    return new Object({ id: e.id, name: e.lastname + " " + e.firstname });
  })
  const JobFilter = [
    <SearchInput source="q" alwaysOn />,
    <AutocompleteInput source="selected_consultant" label="Consultant" choices={consultantTab} defaultValue={localStorage.getItem("userId")} />,
    <AutocompleteInput source="status" label="Statut" choices={[
      { id: 0, name: 'Actif' },
      { id: 1, name: 'Archivé' },
      { id: 2, name: 'Tous' },
    ]} />,
    <AutocompleteInput source="clientId" label="Client" choices={companiesTab} />
  ];
  return JobFilter;

}