import { Create, ReferenceInput, required, SimpleForm, TextInput } from "react-admin";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";

const CompaniesCreate = () => (
  <Create redirect='list'>
    <SimpleForm>
      <CustomTextInput variant="outlined" source="name" label="Nom de l'entreprise" validate={[required()]} />
    </SimpleForm>
  </Create>
);
export default CompaniesCreate;
