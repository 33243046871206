// in src/admin/index.tsx
import { Admin, CustomRoutes, memoryStore, Resource, ThemeProvider } from "react-admin";
import authProvider from "./AuthProvider";
import DataProvider from "./DataProvider";
import PeopleIcon from '@mui/icons-material/People';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import WorkIcon from '@mui/icons-material/Work';
import JobsCreate from "../resources/jobs/JobsCreate";
import JobsEdit from "../resources/jobs/JobsEdit";
import JobsList from "../resources/jobs/JobsList";
import JobsShow from "../resources/jobs/JobsShow";
import { CandidaturesList } from "../resources/candidatures/CandidaturesList";
import { CandidaturesShow } from "../resources/candidatures/CandidaturesShow";
import CandidaturesEdit from "../resources/candidatures/CandidaturesEdit";
import CandidaturesCreate from "../resources/candidatures/CandidaturesCreate";
import CompaniesList from "../resources/companies/CompaniesList";
import CompaniesShow from "../resources/companies/CompaniesShow";
import CompaniesEdit from "../resources/companies/CompaniesEdit";
import CompaniesCreate from "../resources/companies/CompaniesCreate";
import RecipientsShow from "../resources/referents/RecipientsShow";
import RecipientsList from "../resources/referents/RecipientsList";
import RecipientsEdit from "../resources/referents/RecipientsEdit";
import RecipientsCreate from "../resources/referents/RecipientsCreate";
import { Route } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StoreIcon from '@mui/icons-material/Store';
import RecipientsExternalList from "../external/candidate/RecipientsExternalList";
import CreateRecipients from "../external/candidate/CreateRecipients";
import EditRecipients from "../external/candidate/EditRecipients";
import FeedbackAnswer from "../external/feedback/FeedbackAnswer";
import LoginPage from "./LoginPage";
import ForgottenPassword from "./ForgottenPassword";
import ProfilePage from "../resources/ProfilePage";
import CustomLayout from "../components/Layout/CustomLayout";
import ResultView from "../external/candidate/ResultsView/ResultsView";
import MandatsList from "../resources/mandats/MandatsList";
import lhhTheme from "../components/Layout/lhhTheme";
// import { mergeTranslations  } from 'react-admin';
import { i18nProvider } from '../i18nProvider';
import '../App.css'



const App = () => {
  return (
    <>
      <Admin i18nProvider={i18nProvider} dataProvider={DataProvider} theme={lhhTheme} loginPage={LoginPage} authProvider={authProvider} layout={CustomLayout} store={memoryStore()}>
        <CustomRoutes>
          <Route path={"/profile"} element={<ProfilePage />} />
        </CustomRoutes>
        {/* <Resource name="users" list={UsersList} show={UsersShow} edit={UsersEdit} create={UsersCreate} icon={PeopleIcon} options={{ label: "Candidats" }} /> */}
        <Resource name="companies" list={CompaniesList} edit={CompaniesEdit} create={CompaniesCreate} icon={StoreIcon} options={{ label: "Entreprises" }} />
        <Resource name="jobs" list={JobsList} edit={JobsEdit} create={JobsCreate} icon={WorkIcon} options={{ label: "Postes" }} />
        <Resource name="candidatures" list={CandidaturesList} edit={CandidaturesEdit} create={CandidaturesCreate} icon={PeopleIcon} options={{ label: "Candidats" }} />
        <Resource name="recipients" list={RecipientsList} edit={RecipientsEdit} create={RecipientsCreate} icon={ManageAccountsIcon} options={{ label: "Référents" }} />
        <Resource name="mandats" list={MandatsList} icon={NoteAltIcon} options={{ label: "Mandats" }} />
        <CustomRoutes noLayout>
          <Route path={"/candidate" + "/:token" + "/:candidatureId"} element={<RecipientsExternalList />} />
          <Route path={"/candidate" + "/:token" + "/:candidatureId" + "/create"} element={<CreateRecipients />} />
          <Route path={"/candidate" + "/:token" + "/:candidatureId" + "/edit/:id"} element={<EditRecipients />} />
          <Route path={"/feedback" + "/:token" + "/:feedbackId"} element={<FeedbackAnswer />} />
          <Route path={"/forgottenpassword"} element={<ForgottenPassword />} />
          <Route path={"/candidate/statistics" + "/:token" + "/:candidatureId"} element={<ResultView />} />
        </CustomRoutes>
      </Admin>
    </>
  );
};

export default App;
